<template>

    <div class="card">

        <div class="mb-3">
            <div class="mb-1">ОГРН</div>
            <InputMask v-model="formData.ogrn" mask="9999999999999"/>
        </div>

        <div class="mb-3">
            <div class="mb-1">Наименование организации</div>
            <InputText v-model="formData.title"/>
        </div>

        <div class="mb-3">
            <div class="mb-1">Адрес</div>
            <InputText v-model="formData.address"/>
        </div>

        <div class="mb-3">
            <div class="mb-1">Телефон</div>
            <InputMask v-model="formData.phone" mask="+7 (999) 999-99-99? доб. 99999"/>
        </div>

        <div class="mb-3">
            <div class="mb-1">Электронная почта</div>
            <InputText v-model="formData.email"/>
        </div>

        <div class="mb-3">
            <div class="mb-1">Сайт</div>
            <InputText v-model="formData.site"/>
        </div>

        <div class="mb-3">

            <div class="mb-1">Грифы ограничения доступа</div>
            <MultiSelect v-model="formData.accessRestrictionsTypes"
                         :filter="true" :max-selected-labels="1"
                         :options="accessRestrictionTypesRefList"
                         autofocus
                         class="w-600"
                         option-label="title"
                         option-value="val"
                         placeholder="Выберите элементы"
                         selected-items-label="{0} элементов выбрано"
            ></MultiSelect>

        </div>

        <Button class="p-button-outlined p-button-success mt-3" label="Сохранить изменения"
                @click="saveOrganizationInfo"/>

    </div>

</template>

<script>
export default {
    name: "OrganizationInfo",
    data() {
        return {
            formData: {},
            accessRestrictionTypesRef: null,
        }
    },
    methods: {

        async saveOrganizationInfo() {

            try {

                this.$xapi.showBlockModal('Сохранение информации об организации...');

                await this.$xapi.post(
                    '/organization-info',
                    {
                        action: 'saveOrganizationInfo',
                        organizationInfo: this.formData
                    },
                );

                this.getOrganizationInfo();

                this.$xapi.xnotify('Информация об организации сохранена')

            } finally {
                this.$xapi.hideBlockModal();
            }

        },

        async getOrganizationInfo() {

            let response = await this.$xapi.post(
                '/organization-info',
                {
                    action: 'getOrganizationInfo',
                },
            );

            this.formData = response.data.organizationInfo;

        },

        async getAccessRestrictionTypesRef() {

            let response = await this.$xapi.post(
                '/organization-info',
                {
                    action: 'getAccessRestrictionTypesRef',
                },
            );

            console.log(response.data);

            this.accessRestrictionTypesRef = response?.data?.accessRestrictionTypesRef;

        }

    },
    mounted() {
        this.getOrganizationInfo();
        this.getAccessRestrictionTypesRef();
    },
    computed: {

        accessRestrictionTypesRefList() {

            if (!this?.accessRestrictionTypesRef)
                return null;

            if (typeof this?.accessRestrictionTypesRef !== 'object')
                return null;

            return Object.values(this?.accessRestrictionTypesRef)?.map(item => {
                return {
                    title: item?.['@attributes']?.['Название'],
                    val: item?.['@attributes']?.['ИД'],
                };
            });

        }

    }
}
</script>

<style scoped>
.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem;
}

.p-inputtext, .w-600 {
    width: 600px;
}

</style>